import React, { useRef } from 'react';
import { Button, Container, Control, List, ListItem, ModalFormControl, useFileTransfer } from 'components';
import { faDownload, faPaperclip, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { ReportControlContext } from 'modules/reports';
import { Translate, useApi, useIntl } from 'providers';
import { ApiRequest } from 'containers';
import './Attachments.less';
import { ReportsUploadAttachmentRequest } from 'interfaces/api';
import { getFileIcon } from 'utils/file';
import messages from 'messages';

export const Attachments = (props: ReportControlContext) => {

  const { data: { bid, attachment_count }, bindings: { updateItem } } = props;
  const { reports: { uploadAttachment, listReportAttachments, deleteAttachment, downloadAttachment } } = useApi();
  const { download, upload } = useFileTransfer();
  const { formatDate } = useIntl();

  const reloadRef = useRef<() => void>();

  const labels = messages.reports.controls.attachments;

  return (

    <ModalFormControl
      icon={faPaperclip}
      label={labels.label}
      badge={{ count: attachment_count }}
      notifications={labels.notifications}
      keepOpen
      modal={{
        footer: null,
        scrollY: true,
      }}
      form={{
        initialValue: {} as ReportsUploadAttachmentRequest,
        resetOnSuccess: true,
        withUpload: true,
        suppressControls: true,
        request: async (value) => {
          const { attachment, description } = value;
          const { attachments } = await upload({
            request: uploadAttachment,
            data: { bid, attachment, description },
          });
          await updateItem(bid, { ...props.data, attachments, attachment_count: attachments.length });
        },
        onSuccess: () => {
          reloadRef.current();
        },
        children: ({ FileChooser, Input, submit, value, loading, reset }) => {

          const getControls = (listItem: ListItem) => [(
            <Control
              icon={faTrashAlt}
              key={'deleteAttachment'}
              tooltip
              label={labels.delete.label}
              confirm={labels.delete.confirm}
              notifications={labels.delete.notifications}
              onClick={async () => {
                await deleteAttachment({ bid, anid: listItem.id as number });
                reloadRef.current();
              }}
            />
          )];

          const request = () => listReportAttachments({ bid });

          return (
            <Container>

              <Container padding>

                <FileChooser property={'attachment'}/>
                {value.attachment && (
                  <>
                    <Input property={'description'} label={labels.form.description}/>

                    <Button type={'primary'} loading={loading} onClick={() => submit()} block className={'margin-top-1'}>
                      <Translate message={labels.form.submit}/>
                    </Button>

                    <Button loading={loading} onClick={() => reset()} block className={'margin-top-1'}>
                      <Translate message={messages.general.cancel}/>
                    </Button>
                  </>
                )}

              </Container>

              {!value.attachment && (
                <ApiRequest
                  staticLoader
                  request={request}
                  bindReload={(reload) => {
                    reloadRef.current = reload;
                  }}
                  onLoaded={data => updateItem(bid, { ...props.data, attachments: data.attachments, attachment_count: data.attachments.length })}
                  children={({ data }) => {

                    return data.attachments.length > 0
                      ? (
                        <List
                          getControls={getControls}
                          onSelect={async (item) => {
                            const anid = item.id as number;
                            await download({
                              request: downloadAttachment,
                              data: { anid, bid },
                            });
                          }}
                          items={data.attachments.map((attachment) => {

                            const fields = [{
                              label: <Translate message={labels.uploaded}/>,
                              value: formatDate(attachment.created_at, { ago: true }),
                            }];

                            const { anid, description, filename } = attachment;
                            const hasDescription = description && description.length > 0;

                            return {
                              fields,
                              id: anid,
                              title: hasDescription ? description : filename,
                              subtitle: hasDescription ? filename : false,
                              icon: getFileIcon(filename),
                              hoverIcon: faDownload,
                              className: 'report-attachment-item',
                            } as ListItem;
                          })}
                        />
                      )
                      : null;
                  }}
                />
              )}

            </Container>
          );

        },
      }}
    />
  );

};
