import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import makeError from 'make-error';
import * as Errors from 'interfaces/api/errors';
import { getDeviceId, getDeviceName, getPlatform, isWeb } from 'utils/device';
import { forEach, fromPairs, isArray, isEmpty, reduce } from 'lodash';
import { api } from './requests';
import { arrayify, transformResponse } from 'utils/helpers';
import * as Sentry from '@sentry/react';
import { Color } from 'interfaces';
import { ColoredLogMessage, Logger, MetaLogMessage, StyledLogMessage } from 'providers/LoggerProvider';
import { blobToBase64String } from 'blob-util';

export interface ApiConfig {
  endpoint?: string;
  locale?: string;
  lid?: number;
  authToken?: string;
  defaultRequestConfig?: ApiRequestConfig;
  version?: string;
  target?: string;
  onUnauthorizedError?: () => void;
  checkResponseHeaders?: (headers: any) => void;
  logger?: Logger;
}

export interface PaginationResponse<T> {
  results: T[];
  indexStart: number;
  indexEnd: number;
  hasMore: boolean;
}

export type ApiRequestConfig = Omit<AxiosRequestConfig, 'headers'>;

export class ApiRequestCancelError extends makeError.BaseError {
}

export class ApiRequestTimeoutError extends makeError.BaseError {
}

export function createCancelToken() {
  return axios.CancelToken.source();
}

export enum HttpMethod {
  GET = 'GET',
  PATCH = 'PATCH',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export class Client {

  readonly config: ApiConfig;

  protected axios: AxiosInstance;

  constructor(config: ApiConfig) {

    this.config = config;
    this.axios = axios.create();

    const { authToken, lid, locale, target, version } = this.config;

    const headers: any = {
      Accept: 'application/json',
      'Accept-Language': locale,
      'X-LID': lid,
      'X-Requested-With': 'xmlhttprequest',
      'X-Version': version,
      'X-Target': target,
    };

    if (!isEmpty(authToken)) {
      headers['Authorization'] = 'Bearer ' + authToken;
    }

    Object.assign(this.axios.defaults, { headers });

  }

  public createFormData(data: any) {

    const formData = new FormData();

    forEach(data, (value, key) => {
      if (value !== undefined) {
        if (isArray(value)) {
          forEach(value, (v) => {
            formData.append(key + '[]', v);
          });
        } else {
          formData.append(key, value);
        }
      }
    });

    return formData;
  }

  get = (path: string, data: any, config?: ApiRequestConfig) => this.request(path, HttpMethod.GET, data, config);
  post = (path: string, data: any, config?: ApiRequestConfig) => this.request(path, HttpMethod.POST, data, config);
  put = (path: string, data: any, config?: ApiRequestConfig) => this.request(path, HttpMethod.PUT, data, config);
  patch = (path: string, data: any, config?: ApiRequestConfig) => this.request(path, HttpMethod.PATCH, data, config);
  delete = (path: string, data: any, config?: ApiRequestConfig) => this.request(path, HttpMethod.DELETE, data, config);

  async request(path: string, method: HttpMethod, data: any, config: ApiRequestConfig = {}) {

    const { endpoint, defaultRequestConfig, locale, onUnauthorizedError } = this.config;

    const headers: any = {
      'Accept-Language': locale,
      'X-Requested-With': 'xmlhttprequest',
      'X-Device-Id': await getDeviceId(),
      'X-Device-Type': await getPlatform(),
    };

    const deviceName = await getDeviceName();
    if (deviceName) {
      headers['X-Device-Name'] = encodeURIComponent(deviceName);
    }

    if (data?.formData) {
      headers['Content-Type'] = 'multipart/form-data';
    }

    let url = endpoint + path;

    if (data) {

      data = data instanceof FormData ? data : { ...data };

      const keyValues = data instanceof FormData ? fromPairs(Array.from(data.entries())) : data;
      const removeParam = (p: string) => data instanceof FormData ? data.delete(p) : delete data[p];

      forEach(keyValues, (value: any, param: string) => {
        if (path.indexOf(`{${param}}`) > -1) {
          url = url.replace(`{${param}}`, value);
          removeParam(param);
        }
      });

      if (!await isWeb() && data instanceof FormData) {
        data = reduce(await Promise.all(
          Array.from(data.entries()).map(async ([key, value]) => [
            key.replace('[]', ''),
            value instanceof File ? { type: value.type, name: value.name, data: await blobToBase64String(value) } : value,
          ]),
        ), (result, [key, value]: [string, any]) => {
          if (!Object.hasOwn(result, key)) {
            result[key] = value;
          } else {
            result[key] = [...arrayify(result[key]), value];
          }
          return result;
        }, {} as any);
      }

    }

    const { responseType, ...configRest } = config;

    const axiosConfig: AxiosRequestConfig = {
      ...defaultRequestConfig,
      url,
      method,
      headers,
      transitional: {
        clarifyTimeoutError: true,
      },
      data: method === HttpMethod.GET ? undefined : data,
      params: method === HttpMethod.GET ? data : undefined,
      ...configRest,
      responseType: await isWeb() ? responseType : 'json',
      withCredentials: true,
    };

    try {

      // the actual request
      const result = await this.axios.request(axiosConfig);
      const response = await transformResponse(result, config.responseType === 'blob');

      this.config.logger?.debug(
        new ColoredLogMessage(axiosConfig.method, Color.Silver),
        new StyledLogMessage(path.replace('/api/', ''), { fontWeight: 'lighter' }),
        new StyledLogMessage(result.status, { fontWeight: 'lighter' }),
        new MetaLogMessage('response', response),
        new MetaLogMessage('config', axiosConfig),
      );

      // transform the response
      return response;

    } catch (error) {

      let apiError: Error = error;

      Sentry.setContext('api_headers', headers);

      if (axios.isCancel(error)) {
        apiError = new ApiRequestCancelError(error.message);
      }

      if (error.code === 'ETIMEDOUT') {
        apiError = new ApiRequestTimeoutError(error.message);
      }

      if (error.response?.data?.constructor?.name === 'Blob') {
        const errorText = await error.response.data.text();
        apiError = JSON.parse(errorText).error;
      }

      if (error.response?.status === 401 && onUnauthorizedError) {
        onUnauthorizedError();
      }

      const responseError = error.response?.error || error.response?.data?.error;

      this.config.logger?.error(
        new ColoredLogMessage(axiosConfig.method, Color.Silver),
        new StyledLogMessage(path.replace('/api/', ''), { fontWeight: 'lighter' }),
        new StyledLogMessage(error.response?.status, { fontWeight: 'lighter' }),
        new MetaLogMessage('error', error),
        new MetaLogMessage('config', axiosConfig),
      );

      if (responseError) {
        // @ts-expect-error todo
        if (Errors[responseError.name]) {
          // @ts-expect-error todo
          throw new Errors[responseError.name](responseError.message);
        }
        const errorClass = makeError(responseError.name || 'Unknown');
        throw new errorClass(responseError.message);
      }

      throw apiError;
    }

  }

}

export const getClient = (config: ApiConfig) => api(new Client(config));
