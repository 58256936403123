// hook to get missing freetext
import { useCallback, useMemo } from 'react';
import { find, findIndex, flatten, set, uniqBy } from 'lodash';
import { useOrderRulesContext } from './OrderRulesProvider';
import { OrderRequirement } from 'interfaces/api';

export type PendingOrderRuleMissingFreetext = {
  index: number;
  requirement: OrderRequirement;
};

export const useMissingFreetext = () => {

  const { getErrorsByName, pending, setPending, errors } = useOrderRulesContext();

  // get missing freetext from errors
  const missingFreetextErrors = useMemo(() => getErrorsByName('OrderRulesFreetextMandatoryError'), [getErrorsByName]);

  // create missing freetext array
  const missingFreetext: PendingOrderRuleMissingFreetext[] = useMemo(() => {
    const errors = flatten(missingFreetextErrors?.map(
      result => uniqBy(result.errors, e => e.message).map(
        ({ message: shortName }) => ({
          index: result.idx,
          requirement: find(pending.orders[result.idx].requirements, { shortName }),
        }),
      ),
    ));

    return errors.length > 0 ? errors : undefined;

  }, [missingFreetextErrors]);

  // update values functionality
  const updateValues = useCallback((values: PendingOrderRuleMissingFreetext[]) => {

    const orders = [...errors.map(e => e.order)];

    values.forEach((v) => {
      const reqIndex = findIndex(orders[v.index].requirements, { shortName: v.requirement.shortName });
      set(orders, `${v.index}.requirements[${reqIndex}`, v.requirement);
    });

    setPending({ ...pending, orders, isUpsert: true });

  }, [pending, setPending]);

  return { missingFreetext, updateValues };

};
